import {inject, Injectable, OnDestroy} from "@angular/core";
import {Auth, authState, getAuth} from "@angular/fire/auth";
import {CanActivateFn, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Account, AccountRole, UpdateAccountRoleRequest} from "../models/account.model";
import {environment} from "../../environments/environment";
import {catchError, map, take} from "rxjs/operators";
import {handleError} from "../utils/helpers";
import {AuthService} from "./auth.service";
import {FelixUser} from "../models/user.model";
import {BehaviorSubject, filter, firstValueFrom, Observable, Subject, takeUntil} from "rxjs";
import {SubscriptionStatus} from "../models/subscription.model";

@Injectable({
  providedIn: 'root'
})
export class AccountService implements OnDestroy {
  private authService = inject(AuthService);
  private apiPrefix = "accounts"
  private apiUrl = `${environment.apiUrl}/${this.apiPrefix}`;
  activeAccount: BehaviorSubject<Account | null> = new BehaviorSubject<Account | null>(null);
  private auth = inject(Auth);
  private localStorageKey = "activeAccountId";
  user$ = authState(this.auth);
  private readonly _destroy$ = new Subject<void>();

  constructor(private router: Router, private http: HttpClient) {
    this.authService.userDetails.pipe(takeUntil(this._destroy$)).subscribe((user?: FelixUser) => {
      if (user && !this.activeAccount.value) {
        let accountId = user.ownedAccounts[0].accountId;
        try {
          if (localStorage.getItem(this.localStorageKey)) {
            accountId = Number(localStorage.getItem(this.localStorageKey));
          }
        } catch(err) {
          console.error(err);
        }
        this.getAccount(accountId);
      }
    })
  }

  //create new account
  createAccount(accountName: string) {
    return this.http.post<{data: Account}>(this.apiUrl, {accountName})
      .pipe(
        map(response => response.data),
        catchError(handleError<Account>('createAccount'))
      )
  }

  //create or update account role
  createUpdateAccountRole(accountId: number, updates: UpdateAccountRoleRequest) : Observable<AccountRole> {
    return this.http.put<{data: AccountRole}>(this.apiUrl + `/${accountId}/roles`, updates)
      .pipe(
        map(response => response.data),
        catchError(handleError<AccountRole>('createUpdateAccountRole'))
      );
  }

  //delete account role
  deleteAccountRole(accountId: number, accountRoleId: string) : Observable<AccountRole> {
    return this.http.delete<{data: AccountRole}>(this.apiUrl + `/${accountId}/roles/${accountRoleId}`)
      .pipe(
        map(response => response.data),
        catchError(handleError<AccountRole>('deleteAccountRole'))
      );
  }

  //gets account from database
  getAccount(accountId: number) : void {
    this.activeAccount.next(null);
    this.http.get<{data: Account}>(this.apiUrl + `/${accountId}`)
      .pipe(
        map(response => response.data),
        catchError(handleError<Account>('getAccount'))
      ).subscribe((account) => {
      this.setActiveAccount(account);
    })
  }

  setActiveAccount(account: Account) {
    this.activeAccount.next(null);
    try {
      localStorage.setItem(this.localStorageKey, account.accountId.toString());
    } catch(err) {
      console.error("local storage not available")
    }
    this.activeAccount.next(account);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
