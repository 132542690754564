import {
  Component,
  ViewChild,
  EventEmitter,
  Output,
  OnInit,
  AfterViewInit,
  Input,
  Directive,
  ViewContainerRef, TemplateRef, ElementRef
} from '@angular/core';
import {FormGroup, FormBuilder, FormsModule} from '@angular/forms';
import {InputTextModule} from "primeng/inputtext";
import {Address} from "../../../models/entity.model";
declare var google;
@Directive({
  selector: '[addressAutoComplete]',
  standalone: true
})
export class AutocompleteDirective implements OnInit, AfterViewInit {
  @Input() addressType: string = "address";
  @Output() setAddress: EventEmitter<any> = new EventEmitter();

  @Input() autocompleteInput: string = "";
  queryWait: boolean = false;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.element.nativeElement,
      {
        fields: ["address_components"],
        componentRestrictions: { country: 'US' },
        types: ["address"] // 'establishment' / 'address' / 'geocode'
      });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: any) {
    let address: Address = {street: '', city: '', state: '', postalCode: ''}

    for (const component of place.address_components) {
      // @ts-ignore remove once typings fixed
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          address.street = `${component.long_name} ${address.street}`;
          break;
        }

        case "route": {
          address.street += component.short_name;
          break;
        }

        case "postal_code": {
          address.postalCode = `${component.long_name}`;
          break;
        }

        case "locality":
          address.city = `${component.long_name}`;
          break;

        case "administrative_area_level_1": {
          address.state = `${component.short_name}`
          break;
        }
      }
    }
    this.autocompleteInput = address.street;
    this.setAddress.emit(address);
  }

}
