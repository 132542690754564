import {inject, Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHandlerFn
} from '@angular/common/http';

import {Observable, switchMap} from "rxjs";
import {Auth, idToken} from "@angular/fire/auth";
import {AccountService} from "../services/account.service";
import {take} from "rxjs/operators";

export function tokenInterceptor(request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
  const auth = inject(Auth);
  const accountService = inject(AccountService);
  const idToken$ = idToken(auth);
    if (request.url.indexOf('oauthCallback') > -1 ) {
      return next(request)
    }

    return idToken$.pipe(
      take(1),
      switchMap(token => {
        const t = accountService.activeAccount.value;
        request = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${token}`).set('Felix-Account-Id', accountService.activeAccount.value ? accountService.activeAccount.value.accountId.toString() : "")
        });
        return next(request);
      })
    )
}
