
<p-scrollPanel [style]="{ width: '100%'}">
  <form [formGroup]="entityForm" (ngSubmit)="createEntity()">
    <div class="flex flex-column gap-2">
      <div class="flex align-items-start">
        <div class="flex flex-column gap-2 mr-4">
          <label for="name">Legal Name</label>
          <input
            pInputText
            id="name"
            formControlName="name"
          />
          <small id="username-help">
            Your company's full legal name. (Including LLC, Inc, etc.)
          </small>
        </div>
<!--        <div class="flex flex-column gap-2">-->
<!--          <label for="username">Logo</label>-->
<!--          <div class="flex align-items-center mb-3">-->
<!--            <p-avatar-->
<!--              icon="pi pi-image"-->
<!--              styleClass="mr-2"-->
<!--              size="large"-->
<!--              shape="circle" />-->
<!--            <p-fileUpload-->
<!--              mode="basic"-->
<!--              chooseLabel="Choose"-->
<!--              chooseIcon="pi pi-upload"-->
<!--              name="demo[]"-->
<!--              url="https://www.primefaces.org/cdn/api/upload.php"-->
<!--              accept="image/*"-->
<!--              maxFileSize="1000000"/>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div formArrayName="dbaNames" *ngFor="let dba of dbasControl.controls; let i = index" class="flex flex-column max-w-22rem gap-2 mr-4">
        <label>DBA Name</label>
        <div class="flex align-items-center">
        <input
          pInputText
          [formControlName]="i"
          aria-describedby="username-help"
        />
        <p-button (onClick)="removeDBA(i)" icon="pi pi-times" class="ml-2" severity="secondary" [outlined]="true"></p-button>
        </div>
      </div>
      <p-button (onClick)="addDBA()" icon="pi pi-plus" size="small" [text]="true" label="Add DBA"></p-button>
    </div>
    <p-divider />
    <h3>Business Address</h3>
    <div formGroupName="address" class="flex flex-column gap-2 mb-2">
      <label for="street">Street</label>
      <input
        addressAutoComplete
        (setAddress)="updateAddressFromAutocomplete($event, 'address')"
        pInputText
        class="w-8"
        formControlName="street"
        placeholder="Enter an address"
        id="street"
        aria-describedby="username-help"
      />
      <div class="flex flex-wrap gap-2">
        <div class="flex flex-column gap-2">
          <label for="city">City</label>
          <input
            pInputText
            id="city"
            formControlName="city"
            aria-describedby="username-help"
          />
        </div>
        <div class="flex align-items-center">
          <div class="flex flex-column gap-2 mr-2">
            <label for="state">State</label>
            <p-dropdown
              id="state"
              name="state"
              [options]="states"
              optionLabel="abbreviation"
              optionValue="abbreviation"
              formControlName="state"
              placeholder="" />
          </div>
          <div class="flex flex-column gap-2">
            <label for="username">Postal Code</label>
            <input
              pInputText
              id="postalCode"
              formControlName="postalCode"
              aria-describedby="username-help"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="useBusinessAddressAsAgent"
        [ngModelOptions]="{standalone: true}"
        inputId="raAddressSame" />
      <label for="raAddressSame" class="ml-1">Registered Agent address is same as business address.</label>
      <h3 *ngIf="!useBusinessAddressAsAgent">Registered Agent Address</h3>
      <div *ngIf="!useBusinessAddressAsAgent" formGroupName="registeredAgentAddress" class="flex flex-column gap-2 mb-2">
        <label for="street">Street</label>
        <input
          pInputText
          addressAutoComplete
          (setAddress)="updateAddressFromAutocomplete($event, 'registeredAgentAddress')"
          id="raStreet"
          class="w-8"
          formControlName="street"
          placeholder="Enter an address"
          aria-describedby="username-help"
        />
        <div class="flex flex-wrap gap-2">
          <div class="flex flex-column gap-2">
            <label for="city">City</label>
            <input
              pInputText
              id="raCity"
              formControlName="city"
              aria-describedby="username-help"
            />
          </div>
          <div class="flex align-items-center">
            <div class="flex flex-column gap-2 mr-2">
              <label for="state">State</label>
              <p-dropdown
                id="raState"
                name="state"
                [options]="states"
                optionLabel="abbreviation"
                optionValue="abbreviation"
                formControlName="state"
                placeholder="" />
            </div>
            <div class="flex flex-column gap-2">
              <label for="username">Postal Code</label>
              <input
                pInputText
                id="raPostalCode"
                formControlName="postalCode"
                aria-describedby="username-help"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-divider />
    <h3>Business Details</h3>
    <div class="flex flex-column gap-2 mb-2">
      <div class="flex align-items-center flex-wrap mb-3">
        <div class="flex flex-column gap-2 mr-2">
          <label for="entityType">Entity Type</label>
          <p-dropdown
            id="entityType"
            [options]="entityTypes"
            formControlName="entityType"
            placeholder="LLC" />
        </div>
        <div class="flex flex-column gap-2 mr-2">
          <label for="username">Incorporation Date</label>
          <p-inputMask
            formControlName="incorporationDate"
            mask="99/99/9999"
            placeholder="mm/dd/yyyy"
            slotChar="mm/dd/yyyy" />
        </div>
        <div class="flex flex-column gap-2 mt-2">
          <label for="username">EIN</label>
          <p-inputMask mask="99-9999999" formControlName="ein" [unmask]="false" placeholder="99-9999999" id="username"
                       aria-describedby="username-help"
          />
        </div>
      </div>
    </div>
    <p-divider />
    <h3>Business Documents</h3>
    <div class="flex align-items-center">
      <div class="flex flex-column gap-2 mr-2 w-5">
        <div class="bg-gray-50 p-2 border-round">
          <div class="flex flex-column align-items-center justify-content-between">
            <div class="flex align-items-center">
              <span class="pi pi-receipt mr-2"></span>
              <p class="text-sm">Articles of Incorporation</p>
            </div>
            <p-fileUpload
              #articlesUpload
              mode="basic"
              [chooseLabel]="existingArticlesIncorporationDocument ? existingArticlesIncorporationDocument.name : articlesIncorporationFile ? articlesIncorporationFile.name : 'Upload'"
              chooseIcon="pi pi-upload"
              styleClass="docUploadButton"
              name="demo[]"
              maxFileSize="1000000"
              (onSelect)="onFileSelect($event, DocumentType.INCORPORATION_ARTICLES);articlesUpload.clear()" />
          </div>
        </div>
      </div>
      <div class="flex flex-column gap-2 w-5">
        <div class="bg-gray-50 p-2 border-round">
          <div class="flex flex-column align-items-center justify-content-between">
            <div class="flex align-items-center">
              <span class="pi pi-building-columns mr-2"></span>
              <p class="text-sm">EIN Verification Letter</p>
            </div>
            <p-fileUpload
              #einUpload
              mode="basic"
              [chooseLabel]="existingEinVerificationDocument ? existingEinVerificationDocument.name : einVerificationFile ? einVerificationFile.name : 'Upload'"
              chooseIcon="pi pi-upload"
              styleClass="docUploadButton"
              name="demo[]"
              maxFileSize="1000000"
              (onSelect)="onFileSelect($event, DocumentType.EIN_VERIFICATION);einUpload.clear()" />
          </div>
        </div>
      </div>
    </div>
    <p-divider />
    <h3>Advanced</h3>
    <div class="flex flex-column gap-2 mr-2">
      <label for="username">Parent Entity</label>
      <p-dropdown
        optionLabel="name"
        [options]="entities"
        [filter]="true"
        filterBy="name"
        optionValue="entityId"
        [showClear]="true"
        formControlName="parentId"
        placeholder="Select an Entity">
        <ng-template pTemplate="selectedItem" let-selectedOption>
          <div class="flex align-items-center gap-2">
            <img
              src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
              style="width: 18px" />
            <div>{{ selectedOption.name }}</div>
          </div>
        </ng-template>
        <ng-template let-entity pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ entity.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="flex flex-column gap-2 mr-2 mt-3">
      <label for="username">Other Attributes</label>
      <div formArrayName="attributes" *ngFor="let attribute of attributesControl.controls; let i = index" class="flex justify-content-between align-items-center">
        <form [formGroup]="attribute">
          <p-autoComplete
            [showClear]="true"
            formControlName="key"
            [suggestions]="filteredItems"
            [lazy]="true"
            (completeMethod)="filterItems($event)"
            [dropdown]="true"
            (onSelect)="updateEntityAttributeKeyId($event, attribute)"
            styleClass="mr-2"
            optionValue="key"
            optionLabel="key" />
          <input formControlName="value" pInputText />
        </form>
      </div>
      <p-button (onClick)="addAttribute()" icon="pi pi-plus" size="small" [text]="true" label="Add Attribute"></p-button>

    </div>
  </form>
  <p-divider></p-divider>
  <div class="flex align-items-center gap-2 my-2 w-full">
    <div class="flex align-items-center justify-content-between w-full">
      <div>
        <p-button type="submit" [loading]="loading && closeAfterSave"  [disabled]="entityForm.invalid" (click)="createEntity(true)" label="Save"></p-button>
        <p-button *ngIf="!_entity" type="submit" [loading]="loading && !closeAfterSave" styleClass="ml-2" [outlined]="true" [disabled]="entityForm.invalid" (click)="createEntity()" label="Save & Add Another"></p-button>
      </div>
      <p-button *ngIf="_entity" severity="danger" styleClass="mr-2" [outlined]="true" (click)="confirmDelete($event)" icon="pi pi-trash" label="Delete"></p-button>
    </div>
    <p-confirmDialog />
  </div>
</p-scrollPanel>
