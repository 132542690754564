<div class="flex flex-column justify-content-between h-full">
  <div>
    <div class="mb-4">
      <p-dropdown
        #accountDropdown
        [options]="accountRoles"
        [(ngModel)]="accountDropdownValue"
        (onChange)="accountDropdown.resetFilter(); selectNewAccount($event)"
        [filter]="accountRoles.length > 5"
        filterBy="account.name"
        optionLabel="account.name"
        styleClass="w-full font-bold text-color-secondary accountsDropdown"
        placeholder="Select an Account">
        <ng-template let-accountRole pTemplate="item">
          <div *ngIf="accountRole.account.name === 'Accounts Home'">
            <div class="flex align-items-center">
              <span class="pi pi-home mr-2"></span>
              <div>{{ accountRole.account.name }}</div>
            </div>
            <p-divider styleClass="mb-0"></p-divider>
          </div>
          <div *ngIf="accountRole.account.name !== 'Accounts Home' && accountRole.account.name !== 'Create New Account'">{{ accountRole.account.name }}</div>
          <div class="pt-0" (click)="showAddAccountDialog = true" [style]="{'margin-top': '-7px'}" *ngIf="accountRole.account.name === 'Create New Account'">
            <p-divider styleClass="mt-0 pt-0"></p-divider>
            <div class="flex align-items-center">
              <span class="pi pi-plus mr-2"></span>
              <div>{{ accountRole.account.name }}</div>
            </div>
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <p-divider></p-divider>
    <p-panelMenu [model]="items" styleClass="w-full">
      <ng-template pTemplate="item" let-item>
        <div [routerLink]="item.routerLink" [queryParams]="item.queryParams" pRipple class="flex align-items-center px-3 py-2 cursor-pointer">
          <i [class]="item.icon + ' text-primary'"></i>
          <span class="ml-2 ">
                    {{ item.label }}
                </span>
          <p-badge *ngIf="item.badge" class="ml-auto" [value]="item.badge" />
          <span *ngIf="item.shortcut" class="ml-auto border-1 surface-border
                border-round surface-100 text-xs p-1">
                    {{ item.shortcut }}
                </span>
        </div>
      </ng-template>
    </p-panelMenu>
  </div>
  <div *ngIf="subscription?.status === 'TRIALING'" class="border-1 border-round border-gray-200 p-3 mb-4 text-sm text-color-secondary">
    <div class="flex align-items-center mt-0 mb-2"><span class="text-sm text-color-secondary">Felix Pro Trial</span><span tooltipStyleClass="text-sm" pTooltip="After free trial ends you won't have access to some features or functions in the app." class="pi pi-info-circle ml-2 text-color-secondary text-sm"></span></div>
    <p-progressBar [value]="(getRemainingDays(subscription?.currentPeriodEnd) / 7) * 100" [showValue]="false" [style]="{ height: '6px' }" />
    <div class="flex align-items-center justify-content-between mt-2 mb-0 ">
      <div class="flex align-items-center">
        <span class="text-sm mb-0">{{getRemainingDays(subscription?.currentPeriodEnd)}} days left</span>
        <span class="pi pi-arrow-right ml-2 text-xs"></span>
      </div>
      <span class="underline mb-0 cursor-pointer" [routerLink]="'plan-selection'">Upgrade</span>
    </div>
  </div>
  <div *ngIf="subscription && subscription?.status !== 'TRIALING'" class="border-1 border-round border-gray-200 p-3 mb-4 text-sm text-color-secondary">
    <div class="flex align-items-center mt-0 mb-3 justify-content-between">
      <span class="text-sm text-color-secondary font-bold">{{subscription?.product?.name}}</span>
      <span class="mb-0 text-xs cursor-pointer" [routerLink]="'settings'" [queryParams]="{'tab':'billing'}">Renews {{subscription.currentPeriodEnd | date: 'M/dd/yyyy'}}</span>
    </div>
    <div class="flex align-items-center justify-content-between mt-2 mb-0 ">
      <div class="flex align-items-center">
        <span class="mb-0">Need Help?</span>
        <span class="pi pi-arrow-right ml-2 text-xs"></span>
      </div>
      <a class="underline mb-0 cursor-pointer text-color-secondary" [href]="'mailto:support@tophousetech.com'">Contact Us</a>
    </div>
  </div>
</div>

<p-dialog [(visible)]="showAddAccountDialog" [closable]="false" header="Create New Account" [style]="{ width: '35rem' }" (onHide)="resetAccount()" [modal]="true">
  <form>
    <span class="p-text-secondary block mb-5">Use this to create an entirely separate account with it's own Felix subscription and separate entities.</span>
    <div class="flex flex-column gap-2 mb-3">
      <label for="name" class="font-semibold">New Account Name</label>
      <input pInputText id="name" class="flex-auto" [(ngModel)]="newAccountName" [ngModelOptions]="{standalone: true}" autocomplete="off" />
    </div>
    <div class="p-2 border-round bg-gray-100 text-sm mb-4">
      <p class="flex align-items-start"><span class="pi pi-clipboard mr-2"></span>Account will start on a 7 day free trial of Felix Pro. Upgrade at any time.</p>
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button label="Cancel" (onClick)="resetAccount();showAddAccountDialog = false" severity="secondary"/>
      <p-button [loading]="createAccountLoading"  [disabled]="!newAccountName" (onClick)="createNewAccount()" label="Create Account"/>
    </div>
  </form>
</p-dialog>
