import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {OrgChart} from 'd3-org-chart';
import {Entity, EntityType} from "../../../models/entity.model";
import {DatePipe} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-entity-map',
  standalone: true,
  imports: [],
  templateUrl: './entity-map.component.html',
  styleUrl: './entity-map.component.css',
  providers: [DatePipe]
})
export class EntityMapComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('chartContainer') chartContainer: ElementRef | undefined;
  @Input('_data') set _data(entities: Entity[]) {
    entities.forEach((entity) => {
      if (!entity.parentId) {
        entity.parentId = 0;
      }
      entity["id"] = entity.entityId;
    });
    const temp = [...entities];
    temp.push({entityId: 0, name: "MY BUSINESSES", parentId: null, entityType: EntityType.CORPORATION, id: 0, activities: []});
    this.data = temp;
  }
  chart: any;
  data: any[] = []

  constructor(private datePipe: DatePipe, private router: Router) {}

  ngAfterViewInit() {
    if (!this.chart) {
      this.chart = new OrgChart();
    }
    this.updateChart();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.updateChart();
  }
  updateChart() {
    if (!this.data) {
      return;
    }
    if (!this.chart) {
      return;
    }
    this.chart
      .container(this.chartContainer!.nativeElement)
      .data(this.data)
      .nodeWidth(d => {
        if (d.id === "0") {
          return 80
        } else {
          return 200
        }
      })
      .nodeHeight(d => {
        if (d.id === "0") {
          return 80
        } else {
          return 120
        }
      })
      .nodeContent((d, i, arr, state) => {
        const color = '#FFFFFF';
        if (i === 0) {
          return `<div>
                <img src="https://icons.veryicon.com/png/o/miscellaneous/zr_icon/company-23.png" style="padding:10px;background-color:${color}; 10px;border: 1px solid #E4E2E9;border-radius:100px;width:80px;height:80px;"/>
               </div>`
        } else {
          return `
            <div routerLink="../entities/${d.data.entityId}" style="font-family: 'Inter', sans-serif;background-color:${color}; position:absolute;margin-top:-1px; margin-left:-1px;width:${d.width}px;height:${d.height}px;border-radius:10px;border: 1px solid #E4E2E9">
               <div style="background-color:${color};position:absolute;margin-top:-25px;margin-left:${15}px;border-radius:100px;width:50px;height:50px;" ></div>
               <img src="https://icons.veryicon.com/png/o/miscellaneous/zr_icon/company-23.png" style="position:absolute;margin-top:-20px;margin-left:${20}px;border-radius:100px;width:40px;height:40px;" />

              <div style="color:#08011E;position:absolute;right:20px;top:17px;font-size:10px;"><i class="fas fa-ellipsis-h"></i></div>
              <div style="font-size:15px;color:#08011E;margin-left:20px;margin-top:32px"> ${
            d.data.name
          } </div>
              <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;"> ${
            d.data.entityType
          } </div>
          <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;">EIN: ${
            d.data.ein
          } </div>
          <div style="color:#716E7B;margin-left:20px;margin-top:3px;font-size:10px;">Incorporated: ${
            this.datePipe.transform(d.data.incorporationDate, "MMMM d, y")
          } </div>


           </div>
  `;
        }
      })
      .onNodeClick((d) => {
        if (d.id !== "0") {
          const url = `../entities/${d.data.entityId}`;
          this.router.navigate([url])
        }
      })
      .render();
  }
}
