import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BreadcrumbModule} from "primeng/breadcrumb";
import {CdkCopyToClipboard, ClipboardModule} from '@angular/cdk/clipboard';
import {Button} from "primeng/button";
import {DividerModule} from "primeng/divider";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {TableModule} from "primeng/table";
import {TreeTableModule} from "primeng/treetable";
import {TimelineModule} from "primeng/timeline";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {Entity} from "../../models/entity.model";
import {EntityService} from "../../services/entity.service";
import {DocumentsTableComponent} from "../shared/documents-table/documents-table.component";
import {DialogModule} from "primeng/dialog";
import {SecureLinksTableComponent} from "../shared/secure-links-table/secure-links-table.component";
import {TabViewModule} from "primeng/tabview";
import {AddEditEntityComponent} from "../add-new-entity/add-edit-entity.component";
import {SidebarModule} from "primeng/sidebar";
import {CreateSecureLinkComponent} from "../shared/create-secure-link/create-secure-link.component";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {EditorModule} from "primeng/editor";
import {FormsModule} from "@angular/forms";
import {CardModule} from "primeng/card";
import {ActivityService} from "../../services/activity.service";
import {SafeHtmlPipe} from "../../pipes/safeHtml.pipe";
import {NotesEditorComponent} from "../shared/notes-editor/notes-editor.component";
import {InputTextModule} from "primeng/inputtext";
import {Note, NoteCreateRequest} from "../../models/activity.model";
import {ActivityFeedComponent} from "../shared/activity-feed/activity-feed.component";
import {SendDocumentsComponent} from "../shared/send-documents/send-documents.component";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {CalendarModule} from "primeng/calendar";
import {FloatLabelModule} from "primeng/floatlabel";
import {MessageService} from "primeng/api";
import {AccountService} from "../../services/account.service";

@Component({
  selector: 'app-entity-detail',
  standalone: true,
  providers: [UnsubscriberService],
  imports: [
    BreadcrumbModule,
    Button,
    CdkCopyToClipboard,
    DividerModule,
    DatePipe,
    TableModule,
    TreeTableModule,
    NgForOf,
    NgIf,
    TimelineModule,
    DocumentsTableComponent,
    DialogModule,
    SecureLinksTableComponent,
    TabViewModule,
    AddEditEntityComponent,
    SidebarModule,
    CreateSecureLinkComponent,
    ScrollPanelModule,
    EditorModule,
    FormsModule,
    CardModule,
    SafeHtmlPipe,
    NotesEditorComponent,
    InputTextModule,
    ActivityFeedComponent,
    SendDocumentsComponent,
    CalendarModule,
    FloatLabelModule
  ],
  templateUrl: './entity-detail.component.html',
  styleUrl: './entity-detail.component.css'
})
export class EntityDetailComponent implements OnInit {
  breadcrumItems = [
    { label: '' }
  ];
  entityId: string | null;
  loading = false;
  showMagicLinks = false;
  showNewMagicLinkForm = false;
  showSendDocumentsForm = false;
  entity: Entity | undefined;
  newActivityText: string = "";
  showEditor = false;
  showNoteEditor = false;
  home = { label: "", routerLink: '/home' };
  selectedNote: Note | undefined;
  showEditEntity = false;
  newActivityDate = new Date();
  private unsubService = inject(UnsubscriberService);

  constructor(private route: ActivatedRoute, private entityService: EntityService, private accountService: AccountService, private activityService: ActivityService, private router: Router, private messageService: MessageService) {
    this.entityId = this.route.snapshot.paramMap.get('entityId');
  }

  ngOnInit() {
    this.entityService.entities.asObservable().pipe(this.unsubService.takeUntilDestroy).subscribe((entities: Entity[]) => {
      if (!this.entityService.entitiesLoaded) {
        this.entityService.getAllEntities();
      }
      this.entity = entities.find((e) => e.entityId === Number(this.entityId));
      this.breadcrumItems = [{ label: this.entity?.name!}]
    })
  }

  createActivity() {
    if (this.newActivityText) {
      this.loading = true;
      this.activityService.createActivity({
        createdAt: this.newActivityDate.toDateString(),
        description: this.newActivityText,
        entityId: this.entity!.entityId
      }).subscribe((res) => {
        this.entity!.activities = [...this.entity!.activities, res];
        this.showEditor = false;
        this.loading = false;
      })
    }
  }

  noteSaved() {
    this.entityService.getEntity(Number(this.entityId));
    this.showNoteEditor = false;
    this.loading = false;
  }

  openNote(note: Note) {
    this.selectedNote = note;
    this.showNoteEditor = true;
  }

  entityUpdatedHandler(event) {
    this.showEditEntity = false;
    this.refreshEntity()
  }

  entityDeletedHandler(entity: Entity) {
    this.messageService.add({key: 'global', severity: 'success', summary: 'Success', detail: 'Entity deleted' });
    this.entityService.getAllEntities();
    this.router.navigate(["/home"]).then((res) => {})
  }

  getEntityInfo(property): string {
    return this.entity && this.entity[property] ? this.entity[property] : '';
  }

  refreshEntity() {
    this.entityService.getEntity(this.entity!.entityId);
  }

}
