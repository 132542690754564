import {Component, inject} from '@angular/core';
import {Button} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {TreeSelectModule} from "primeng/treeselect";
import {PasswordModule} from "primeng/password";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Auth, authState, getAuth, signInWithEmailAndPassword} from "@angular/fire/auth";
import {User} from "@angular/fire/auth";
import {CardModule} from "primeng/card";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AutoFocus} from "primeng/autofocus";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {MustMatch} from "../../utils/must-match-validator";
import {firstValueFrom, Subscription} from "rxjs";
import {EntityService} from "../../services/entity.service";
import {DividerModule} from "primeng/divider";
import {UnsubscriberService} from "../../services/unsubscriber.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    Button,
    InputTextModule,
    TreeSelectModule,
    PasswordModule,
    FormsModule,
    CardModule,
    AutoFocus,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    RouterLink,
    DividerModule,
    NgForOf
  ],
  providers: [UnsubscriberService],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css'
})
export class RegisterComponent {
  private auth = inject(Auth);
  private authService = inject(AuthService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private unsubService = inject(UnsubscriberService);

  authState$ = authState(this.auth);
  isLoggedIn: any = null;
  returnUrl: string = "/home";
  registerForm: FormGroup;
  showError = false;
  errorMessage = "";
  loading = false;
  features = [
    "Unlimited Entities",
    "Entity Relationship Mapping",
    "One Click Document Sending",
    "Secure Links Sharing",
    "Live Chat Support",
  ]
  constructor(private fb: FormBuilder, private entityService: EntityService) {
    this.authState$.pipe(this.unsubService.takeUntilDestroy).subscribe((aUser: User | null) => {
      //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
      this.isLoggedIn = aUser;
    });
    this.route.queryParams.pipe(this.unsubService.takeUntilDestroy).subscribe(params => {
      if (params['returnUrl']) {
        this.returnUrl = params['returnUrl'];
      }
    });
    this.registerForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$')]],
      confirmPassword: ["", Validators.required]
    },
      {
        validator: MustMatch("password", "confirmPassword"),
      })
  }

  async register() {
      this.loading = true;
      try {
        await firstValueFrom((this.authService.register(this.registerForm.value)));
        await this.authService.login(this.registerForm.value.email, this.registerForm.value.password);
        this.authService.getUserDetails().subscribe();
        if (!this.auth.currentUser?.emailVerified) {
          await this.authService.sendVerificationEmail();
        }
        await this.router.navigate([this.returnUrl]);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        throw err;
      }
  }
}
