import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AvatarModule} from "primeng/avatar";
import {Button} from "primeng/button";
import {DividerModule} from "primeng/divider";
import {DropdownModule} from "primeng/dropdown";
import {FileSelectEvent, FileUploadModule} from "primeng/fileupload";
import {InputMaskModule} from "primeng/inputmask";
import {InputTextModule} from "primeng/inputtext";
import {ConfirmationService, MessageService, PrimeTemplate} from "primeng/api";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {Address, Entity, EntityAttribute, EntityAttributeKey, EntityType} from "../../models/entity.model";
import {EntityService} from "../../services/entity.service";
import {firstValueFrom, Subject, takeUntil} from "rxjs";
import {Document, DocumentType} from "../../models/document.model";
import {DocumentService} from "../../services/document.service";
import {pastDateValidator} from "../../utils/past-date-validator";
import {states} from "../../constants/states";
import {UnsubscriberService} from "../../services/unsubscriber.service";
import {AutocompleteDirective} from "../shared/autocomplete/autocomplete.component";
import {CheckboxModule} from "primeng/checkbox";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {AutoCompleteCompleteEvent, AutoCompleteModule} from "primeng/autocomplete";
import dayjs from "dayjs";
import {ConfirmDialogModule} from "primeng/confirmdialog";

@Component({
  selector: 'app-add-edit-entity',
  standalone: true,
  imports: [
    AvatarModule,
    Button,
    DividerModule,
    DropdownModule,
    FileUploadModule,
    InputMaskModule,
    InputTextModule,
    PrimeTemplate,
    ReactiveFormsModule,
    ScrollPanelModule,
    AutocompleteDirective,
    CheckboxModule,
    NgIf,
    FormsModule,
    NgForOf,
    AutoCompleteModule,
    ConfirmDialogModule
  ],
  providers: [UnsubscriberService],
  templateUrl: './add-edit-entity.component.html',
  styleUrl: './add-edit-entity.component.css'
})
export class AddEditEntityComponent implements OnInit {
  @Input()
  set entity(entity: Entity) {
    if (entity) {
      this._entity = entity;
      this.entityForm.patchValue({
        name: entity.name,
        description: entity.description,
        address: {
          street: entity.address?.street,
          city: entity.address?.city,
          state: entity.address?.state,
          postalCode: entity.address?.postalCode
        },
        registeredAgentAddress: {
          street: entity.registeredAgentAddress?.street,
          city: entity.registeredAgentAddress?.city,
          state: entity.registeredAgentAddress?.state,
          postalCode: entity.registeredAgentAddress?.postalCode
        },
        ein: entity.ein,
        incorporationDate: dayjs(entity.incorporationDate).format('MM/DD/YYYY'),
        entityType: entity.entityType,
        parentId: entity.parentId
      });

      entity.dbaNames?.forEach((dba) => (this.entityForm.controls["dbaNames"] as FormArray).push(this.fb.control(dba, Validators.required)));

      entity.attributes?.forEach((attr: EntityAttribute) => (this.addAttribute(attr)))

      this.useBusinessAddressAsAgent = false;
      this.existingEinVerificationDocument = this._entity.documents?.find((doc) => doc.documentType === DocumentType.EIN_VERIFICATION);
      this.existingArticlesIncorporationDocument = this._entity.documents?.find((doc) => doc.documentType === DocumentType.INCORPORATION_ARTICLES);
    }
  }
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() complete: EventEmitter<{entity, closeModalOnFinish}> = new EventEmitter<{entity, closeModalOnFinish}>();
  private unsubService = inject(UnsubscriberService);
  @ViewChild('businessStreetAddress') addresstext: any;

  loading = false;
  closeAfterSave = false;
  _entity: Entity | undefined;
  entities: Entity[] = [];
  entityForm: FormGroup;
  entityTypes = Object.values(EntityType);
  existingEinVerificationDocument: Document | undefined;
  existingArticlesIncorporationDocument: Document | undefined;
  einVerificationFile: File | undefined;
  articlesIncorporationFile: File | undefined;
  states = states;
  useBusinessAddressAsAgent = true;
  protected readonly DocumentType = DocumentType;
  filteredItems: any = [];
  entityAttributeKeys: EntityAttributeKey[] = [];
  constructor(private fb: FormBuilder, private entityService: EntityService, private documentService: DocumentService, private confirmationService: ConfirmationService) {
    this.entityForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      address: this.fb.group({
        street: [''],
        city: [''],
        state: [''],
        postalCode: ['']
      }),
      registeredAgentAddress: this.fb.group({
        street: [''],
        city: [''],
        state: [''],
        postalCode: ['']
      }),
      dbaNames: this.fb.array([]),
      attributes: this.fb.array([] as FormGroup[]),
      ein: ['', Validators.required],
      incorporationDate: ['', [Validators.required, pastDateValidator]],
      entityType: [EntityType.CORPORATION, Validators.required],
      parentId: undefined
    });
  }

  ngOnInit() {
    this.entityService.entities.asObservable().pipe(this.unsubService.takeUntilDestroy).subscribe((entities: Entity[]) => {
      this.entities = entities;
    });
  }

  async createEntity(closeModalOnFinish = false) {
    this.closeAfterSave = closeModalOnFinish;
    if (this.entityForm.valid && this.entityForm.dirty) {
      this.loading = true;
      if (this.useBusinessAddressAsAgent) {
        this.matchRABusinessAddress()
      }
      if (this._entity) {
        this.entityService.updateEntity(this._entity!.entityId, this.entityForm.value).subscribe(async entity => {
          if (this.articlesIncorporationFile || this.einVerificationFile) {
            await this.uploadDocuments();
            this.existingEinVerificationDocument = undefined;
            this.existingArticlesIncorporationDocument = undefined;
            this.articlesIncorporationFile = undefined;
            this.einVerificationFile = undefined;
            this.loading = false;
            this.complete.emit({entity: this._entity, closeModalOnFinish: closeModalOnFinish});
          } else {
            this.complete.emit({entity, closeModalOnFinish});
            this.loading = false;
          }
        });
      } else {
        this.entityService.createEntity(this.entityForm.value).subscribe(async entity => {
          this._entity = entity;
          if (this.articlesIncorporationFile || this.einVerificationFile) {
            await this.uploadDocuments();
            this.existingEinVerificationDocument = undefined;
            this.existingArticlesIncorporationDocument = undefined;
            this.articlesIncorporationFile = undefined;
            this.einVerificationFile = undefined;
            this.loading = false;
            this.complete.emit({entity: this._entity, closeModalOnFinish: closeModalOnFinish});
          } else {
            this.complete.emit({entity, closeModalOnFinish});
          }
          this.entityForm.reset({
            name: '',
            description: '',
            address: {
              street: '',
              city: '',
              state: '',
              postalCode: ''
            },
            ein: '',
            incorporationDate: '',
            entityType: EntityType.CORPORATION,
          });
          this.loading = false;
        });
      }
    } else if (this.articlesIncorporationFile || this.einVerificationFile) {
      //only the documents were updated
      this.loading = true;
      await this.uploadDocuments();
    }
  }

  onFileSelect(event: FileSelectEvent, docType: DocumentType) {
    if (docType === DocumentType.EIN_VERIFICATION) {
      this.einVerificationFile = event.files[0];
    }
    if (docType === DocumentType.INCORPORATION_ARTICLES) {
      this.articlesIncorporationFile = event.files[0];
    }
  }

  async uploadDocuments() {
    if (this.articlesIncorporationFile) {
      let documentTypeAssignments: any = {};
      documentTypeAssignments[this.articlesIncorporationFile.name] = DocumentType.INCORPORATION_ARTICLES;
      if (this.existingArticlesIncorporationDocument) {
        await firstValueFrom(this.documentService.deleteDocument(this._entity?.entityId, this.existingArticlesIncorporationDocument.documentId));
      }
      await firstValueFrom(this.documentService.uploadDocuments({
        documents: [this.articlesIncorporationFile],
        documentTypeAssignments,
        entityId: this._entity!.entityId
      }));
    }
    if (this.einVerificationFile) {
      let documentTypeAssignments: any = {};
      documentTypeAssignments[this.einVerificationFile.name] = DocumentType.EIN_VERIFICATION;
      if (this.existingEinVerificationDocument) {
        await firstValueFrom(this.documentService.deleteDocument(this._entity?.entityId, this.existingEinVerificationDocument.documentId));
      }
      await firstValueFrom(this.documentService.uploadDocuments({
        documents: [this.einVerificationFile],
        documentTypeAssignments,
        entityId: this._entity!.entityId
      }));
    }
  }

  updateAddressFromAutocomplete(address: Address, controlName: "address" | "registeredAgentAddress") {
    this.entityForm.controls[controlName].patchValue((address))
  }

  matchRABusinessAddress() {
    if (this.useBusinessAddressAsAgent) {
      this.entityForm.controls["registeredAgentAddress"].patchValue(this.entityForm.controls["address"].value);
    }
  }

  addAttribute(attr?: EntityAttribute) {
    if (!this.entityAttributeKeys.length) {
      this.entityService.getEntityAttributeKeys().subscribe((keys) => {
        if (keys) {
          this.entityAttributeKeys = keys;
        }
      })
    }
    const attributes = this.entityForm.controls['attributes'] as FormArray
    attributes.push(this.fb.group({
      key: [attr?.key || '', Validators.required],
      value: [attr?.value || '', Validators.required],
      entityAttributeKeyId: [attr?.entityAttributeKeyId || undefined],
      entityAttributeId: [attr?.entityAttributeId || undefined]
    }, Validators.required));
  }

  removeAttribute(index) {
    const attributes = this.entityForm.controls['attributes'] as FormArray
    attributes.removeAt(index);
  }

  addDBA() {
    const dbas = this.entityForm.controls['dbaNames'] as FormArray
    dbas.push(this.fb.control('', Validators.required));
  }

  removeDBA(index) {
    const dbas = this.entityForm.controls['dbaNames'] as FormArray
    dbas.removeAt(index);
  }

  get attributesControl(): FormArray<FormGroup> {
    return this.entityForm.get('attributes') as FormArray<FormGroup>;
  }
  get dbasControl(): FormArray {
    return this.entityForm.get('dbaNames') as FormArray;
  }

  filterItems(event: AutoCompleteCompleteEvent) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.entityAttributeKeys as any[]).length; i++) {
      let item = (this.entityAttributeKeys as EntityAttributeKey[])[i];
      if (item.key.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(item);
      }
    }

    this.filteredItems = filtered;
  }

  updateEntityAttributeKeyId(event, formControl) {
    formControl.patchValue({entityAttributeKeyId: event.value.entityAttributeKeyId});
  }

  confirmDelete(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Are you sure you want to delete this entity?',
      header: 'Delete Entity',
      icon: 'pi pi-info-circle',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass:"p-button-danger",
      rejectButtonStyleClass:"p-button-text p-button-outline",
      accept: () => {
        this.entityService.deleteEntity(this._entity!.entityId).subscribe((res) => {
          this.delete.emit(this._entity);
        })
      },
      reject: () => {

      },
    });
  }
}
